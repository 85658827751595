exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-quizy-js": () => import("./../../../src/pages/quizy.js" /* webpackChunkName: "component---src-pages-quizy-js" */),
  "component---src-pages-rezerwacja-wizyt-bialoleka-js": () => import("./../../../src/pages/rezerwacja-wizyt-bialoleka.js" /* webpackChunkName: "component---src-pages-rezerwacja-wizyt-bialoleka-js" */),
  "component---src-pages-rezerwacja-wizyt-js": () => import("./../../../src/pages/rezerwacja-wizyt.js" /* webpackChunkName: "component---src-pages-rezerwacja-wizyt-js" */),
  "component---src-pages-rezerwacja-wizyt-wolomin-js": () => import("./../../../src/pages/rezerwacja-wizyt-wolomin.js" /* webpackChunkName: "component---src-pages-rezerwacja-wizyt-wolomin-js" */),
  "component---src-pages-testy-psychologiczne-js": () => import("./../../../src/pages/testy-psychologiczne.js" /* webpackChunkName: "component---src-pages-testy-psychologiczne-js" */),
  "component---src-pages-wyszukiwarka-js": () => import("./../../../src/pages/wyszukiwarka.js" /* webpackChunkName: "component---src-pages-wyszukiwarka-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-gallery-js": () => import("./../../../src/templates/page-gallery.js" /* webpackChunkName: "component---src-templates-page-gallery-js" */),
  "component---src-templates-page-media-js": () => import("./../../../src/templates/page-media.js" /* webpackChunkName: "component---src-templates-page-media-js" */),
  "component---src-templates-page-online-therapy-js": () => import("./../../../src/templates/page-online-therapy.js" /* webpackChunkName: "component---src-templates-page-online-therapy-js" */),
  "component---src-templates-page-pricing-js": () => import("./../../../src/templates/page-pricing.js" /* webpackChunkName: "component---src-templates-page-pricing-js" */),
  "component---src-templates-page-team-js": () => import("./../../../src/templates/page-team.js" /* webpackChunkName: "component---src-templates-page-team-js" */),
  "component---src-templates-page-text-js": () => import("./../../../src/templates/page-text.js" /* webpackChunkName: "component---src-templates-page-text-js" */),
  "component---src-templates-page-tiles-js": () => import("./../../../src/templates/page-tiles.js" /* webpackChunkName: "component---src-templates-page-tiles-js" */),
  "component---src-templates-pages-main-js": () => import("./../../../src/templates/pages-main.js" /* webpackChunkName: "component---src-templates-pages-main-js" */),
  "component---src-templates-quiz-js": () => import("./../../../src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */),
  "component---src-templates-single-person-js": () => import("./../../../src/templates/single-person.js" /* webpackChunkName: "component---src-templates-single-person-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

