export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const { pathname } = routerProps.location;
  const savedPosition = getSavedScrollPosition(pathname);

  if (routerProps.location.pathname === pathname) {
    return false;
  }

  return savedPosition || [0, 0];
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname === (prevLocation && prevLocation.pathname)) {
    return;
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);
};
